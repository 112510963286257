
import { CalcSessionStats } from './calc_sessionstats';
import { getWeek } from './getWeek';
import features from '../scripts/features';
import campaign_data from './campaign_data';

function isValidEdaSession(s) {
    if (!s.data) return false;
    if (!s.data.eda) return false;
    if (s.data.eda.length < 10) return false;

    if (!s.data.scores) return false;
    if (s.data.scores.length<10) return false;

    let dur = s.data.eda[s.data.eda.length-1][0] - s.data.eda[0][0];
    //if(dur < 60*1000) return false;

    return true;
}

const levels = {

    1: {
        levelsucc: 2,
        relax: 50
    },

    2: {
        levelsucc: 2,
        relax: 80,
    },

    3: {
        levelsucc: 2,
        relax: 120,
    },

    4: {
        levelsucc: 2,
        relax: 150,
    },

    5: {
        levelsucc: 2,
        relax: 180,

    },

    6: {
        levelsucc: 2,
        relax: 180,
    },

    7: {
        levelsucc: 2,
        relax: 210,

    },

    8: {
        levelsucc: 6,
        // relax: 210,
    },

    9: {
        decr: 200,
        tree: 3,
        // relax: 210,
    },

    10: {
        tree: 6,
        relax: 1000,
        decr: 500,
        sustain: 500,

    },

    // 11: {
    //     tree: 6,
    //     // relax: 210,
    // },

    // 80: {
    //     relax: 80,
    //     decr: 150,
    //     sustain: 150,
    //     trees: 3
    // }

};

function getStat(ses) {
    let sessions = 0;
    let relax = 0;
    let decr = 0;
    let sustain = 0;
    let trees = 0;
    let flowers = 0;

    let levelsessions = new Array(100).fill(0);
    let levelsucc = new Array(100).fill(0);

    let campdata = campaign_data();

    for (let s of ses) {

        if(! s.campaign ) continue;
        let camp = campdata[s.campaign];
        if(camp.levelType != 'CBTSTRESSGRP') continue;
        let ex = camp.exercises[s.exercise];

        // if (s.type !== 'Waves2Phase' && s.type !== 'WaveBasic' && s.type !== 'Tetris') continue;

        if(ex === undefined) {
            console.log("EX UNDEFINED", s.exercise);
            continue;
        }


        if (!isValidEdaSession(s)) continue;

        let slevel = ex.level;

        levelsessions[slevel] += 1;

        if(s.data.success) levelsucc[slevel] += 1;


        //console.log(s);

        let calcSessionStats = new CalcSessionStats();
        let stats = calcSessionStats.calc_stats(s);

        //console.log(stats);

        if(stats.relaxpts) relax += stats.relaxpts;
        if(stats.decr) decr += stats.decr;
        if(stats.sustain) sustain += stats.sustain;

        sessions += 1;

        if(s.data.creature) {
            if(s.data.creature.type==="tree") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

            if(s.data.creature.type==="flower") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

        }
    }

    return {
        sessions: sessions,
        relax: relax,
        decr: decr,
        sustain: sustain,
        trees: trees,
        levelsessions: levelsessions,
        levelsucc: levelsucc
    }
}

export function calcLevelCbtStressGrp(sessions, courseLevel) {
    console.log("calcLevelCbtStressGrp", courseLevel);

    // console.log("ZZZ", courseLevel);

    let currentLevel = courseLevel;
    let info = ""

    return [currentLevel, info, null];

}
