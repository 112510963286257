
import { CalcSessionStats } from './calc_sessionstats';
import { getWeek } from './getWeek';
import features from '../scripts/features';
// import exercise_data from './exercise_data';
import campaign_data from './campaign_data';

function isValidEdaSession(s) {
    if (!s.data) return false;
    if (!s.data.eda) return false;
    if (s.data.eda.length < 10) return false;

    if (!s.data.scores) return false;
    if (s.data.scores.length<10) return false;

    let dur = s.data.eda[s.data.eda.length-1][0] - s.data.eda[0][0];
    //if(dur < 60*1000) return false;

    return true;
}

const levels = {

    1: {
        sessions: 5,
        relax: 50,
    },


    2: {
        sessions: 10,
        relax: 1000,
        decr: 1000,
        sustain: 1000,
        trees: 1
    },

    3: {
        sessions: 13,
        relax: 3000,
        decr: 3000,
        sustain: 3000,
        trees: 3
    },

    4: {
        sessions: 16,
        relax: 8000,
        decr: 8000,
        sustain: 8000,
        trees: 5
    },

    5: {
        sessions: 20,
        relax: 30000,
        decr: 30000,
        sustain: 30000,
        trees: 10
    },


};

function getStat(ses) {
    let sessions = 0;
    let relax = 0;
    let decr = 0;
    let sustain = 0;
    let trees = 0; 

    let campdata = campaign_data();


    for (let s of ses) {

        if(! ses.campaign ) continue;

        if(campdata[ses.campaign].levelType != 'CTW') continue;

        if (!isValidEdaSession(s)) continue;

        // console.log("====== exdata ", ex.params.point_mult);


        let calcSessionStats = new CalcSessionStats();
        let stats = calcSessionStats.calc_stats(s);

        //console.log(stats);

        if(stats.relaxpts) relax += stats.relaxpts;
        if(stats.decr) decr += stats.decr;
        if(stats.sustain) sustain += stats.sustain;

        sessions += 1;

        if(s.data.creature) {
            if(s.data.creature.type==="tree") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

            if(s.data.creature.type==="flower") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

        }
    }

    return {
        sessions: sessions,
        relax: relax,
        decr: decr,
        sustain: sustain,
        trees: trees
    }
}



export function calcLevelCTW(sessions) {
    // console.log("calcLevelCTW", sessions.length);


    let level = 1;
    let res, info;

    let s = getStat(sessions);
    //console.log("levelstat", s);

    let text = false;

    let currentLevel = 1;

    for(currentLevel in levels) {
        let l = levels[currentLevel];

        //console.log("LEVEL CRITERIA", currentLevel, l);

        text = false;
        if(l.sessions && l.sessions > s.sessions) {
            text = `${l.sessions - s.sessions} sessions` ;
        }
        //console.log("1", text);

        if(l.relax && l.relax > s.relax) {
            if(text) text+=", "; else text="";
            text += `${l.relax - s.relax} relax points` ;
        }

        if(l.decr && l.decr > s.decr) {
            if(text) text+=", "; else text="";
            text += `${l.decr - s.decr} control points` ;
        }

        if(l.sustain && l.sustain > s.sustain) {
            if(text) text+=", "; else text="";
            text += `${l.sustain - s.sustain} balance points` ;
        }

        if(l.trees && l.trees > s.trees) {
            if(text) text+=", "; else text="";
            text += `${l.trees - s.trees} trees` ;
        }

        if(text) break;

    }

    //console.log("FINAL TEXT", currentLevel, text);

    if(text) {
        info = "To reach the next level, you need to get "+text;
    } else {
        info = "You have reached the final level!";
    }


    // features
    let earnedfeatures = [];
    let feat = features();
    // console.log(s);
    for(let f of feat.fg) {
        //console.log(f);

        let pass = true;
        if(f.sessions && s.sessions < f.sessions) pass = false;
        if(f.decr && s.decr < f.decr) pass = false;
        if(f.relax && s.relax < f.relax) pass = false;
        if(f.sustain && s.sustain < f.sustain) pass = false;
        if(f.trees && s.trees < f.trees) pass = false;

        if(pass) {
            earnedfeatures.push(f.id);
        }



    }
    //console.log("earnedfeatures", earnedfeatures);


    //currentLevel = 100;

    return [currentLevel, info, earnedfeatures];

}


