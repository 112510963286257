// import { render } from '@testing-library/react';
import React from 'react';
import Waves2Phase from '../sessions/Waves2Phase';
import WaveBasic from '../sessions/WaveBasic';
import Waves from '../sessions/Waves';
import WaveReinforce from '../sessions/WaveReinforce';
import WavesSubliminal from '../sessions/WavesSubliminal';
import WaveUpdown from '../sessions/WaveUpdown';
import ImageHabituation from '../sessions/ImageHabituation';
import ShrinkImage from '../sessions/ShrinkImage';
import GetImages from '../GetImages';
import Racing from '../sessions/Racing';

import FeedbackSlider from '../sessions/FeedbackSlider';
import VideoEda from '../sessions/VideoEda';
import VideoTutorial from '../sessions/VideoTutorial';

import AudioSession from '../sessions/AudioSession';
import TextSession from '../sessions/TextSession';
import AudioModule from '../sessions/AudioModule';

import AdjustVolume from '../AdjustVolume';
import AdjustDifficulty from '../AdjustDifficulty';

import Tetris from '../sessions/Tetris';

import RawEda from '../sessions/RawEda';
import Story from '../sessions/Story';

import Keywords from '../sessions/Keywords';
import WaveKeywords from '../sessions/WaveKeywords';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';

// import exercise_data from '../scripts/exercise_data';
import campaign_data from '../scripts/campaign_data';
import ReactPlayer from 'react-player';
import instruction_data from '../scripts/instruction_data';

// import { Link } from 'react-router-dom';

import { MyContext } from '../MyContext';

// var _ = require('lodash');


class Exercise extends React.Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element

    console.log("++++++++++++++++++++++++++++++++++++++ EXCERCISE +++++++");

    this.finished_cb = this.finished_cb.bind(this);
    this.store_cb = this.store_cb.bind(this);
    this.backto = this.backto.bind(this);

    this.edaRef = React.createRef();
    // this.accRef = React.createRef();

    this.wakelock = false;
    console.log("COnstructor WAKELOCK set false");

    let c = document.cookie;
    let cc = c.split(';');
    let lang = "en";

    // Now take key value pair out of this array
    for (var i = 0; i < cc.length; i++) {
      let name = cc[i].split('=')[0];
      let value = cc[i].split('=')[1];

      if (name === "lang") lang = value;
    }

    this.state = {
      exercise: this.props.exercise,
      campaign: this.props.campaign,
      eda: false,
      volumeAdjusted: false,
      difficultyAdjusted: false,
      lang: lang
    };

    this.startTs = Date.now();

    this.exercise = this.props.exercise;
    this.campaign = this.props.campaign;

    console.log("Campaign:", this.props.campaign);
    console.log("Exercise:", this.props.exercise);

    this.campdata = campaign_data()

    this.exdata = this.campdata[this.campaign].exercises[this.exercise];

    this.instdata = false;

    if (this.props.exercise) {
      this.instdata = this.exdata.instruction;
      this.videoinstdata = this.exdata.video_instruction;
    } else if (this.props.exercise in instruction_data()) {
      console.log("OLD Instruction data found for exercise", this.props.exercise);
      this.instdata = instruction_data()[this.props.exercise];
    } else {
      this.instdata = {
        en: (
          <div>
            Press Start
          </div>
        ),
      }
    }


    // this.instdata = instruction_data()[this.props.exercise];

    console.log("Instdata:", this.instdata);

    if (this.exdata.eda && this.props.connected !== true) {
      this.props.enableBle()
    }

    //console.log("Exercise: ", this.props.exercise, "user:", this.props.user);
    //console.log("Data: ", this.exdata);

    this.bleDevice = {}
  }

  async pollGroupStart() {
    console.log("Polling group start");

    let resp = await fetch('/api/iamwaiting', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ campaign: this.props.campaign, exercise: this.props.exercise, device: this.context.deviceName})
    });

    let started = await resp.json();

    console.log("Group start data", started);

    if (started === true) {
      this.setState({ groupStarted: true });
      clearInterval(this.pollTimer);
    }

  }



  async componentDidMount() {

    if (this.wakelock === false) {
      try {
        this.wakelock = await navigator.wakeLock.request("screen");
        this.wakelock.onrelease = (() => {
          console.log("WAKELOCK RELEASED")
          this.wakelock = false;
        });
      } catch (error) {
        console.error(error);
      }

      console.log("WAKELOCK SET", this.wakelock);
    }

    let hasBle = false;

    try {
      hasBle = await navigator.bluetooth.getAvailability();
    } catch (error) {
      console.error(error);
    }

    this.setState({ hasBle: hasBle, hasBleReturned: true });
    console.log("hasBle " + hasBle);
  }

  componentDidUpdate() {
    //console.log("Component updated" + JSON.stringify(this.props));
  }

  componentWillUnmount() {

    if (this.wakelock !== false) {
      try {
        this.wakelock.release("screen");
        console.log("WAKELOCK RELEASED");
      } catch (error) {
        console.error(error);
      }
    }

    if (this.pollTimer) {
      clearInterval(this.pollTimer);
    }

    //console.log("componentWillUnmount============================");
    if (!this.bleDevice) {
      return;
    }
    if (!this.bleDevice.gatt) return;
    console.log('Disconnecting from Bluetooth Device...');
    if (this.bleDevice.gatt.connected) {
      this.bleDevice.gatt.disconnect();
    } else {
      console.log('> Bluetooth Device is already disconnected');
    }
  }

  onEda(gsr, acc) {
    // console.log("Exercise: onEda");

    // passing further to individual exercises finally
    if (this.edaRef.current) this.edaRef.current.onEda(gsr, acc);
  }

  onAcc(acc) {
    // console.log("OnAcc", acc);
    if (this.edaRef.current) {
      if (this.edaRef.current.onAcc) this.edaRef.current.onAcc(acc);
    }
  }


  onVolumeAdjusted() {
    this.setState({ volumeAdjusted: true });
  }

  onDifficultyAdjusted(d) {
    console.log("onDifficultyAdjusted ", d);

    if (d === "basic") {
      this.exdata.params.difficulty = d;
      this.exdata.params.l_incr = 1;
      this.exdata.params.l_decr = 1;
      this.exdata.params.r_incr = 1;
      this.exdata.params.r_decr = 1;
    }

    if (d === "medium") {
      this.exdata.params.difficulty = d;
      this.exdata.params.l_incr = 2;
      this.exdata.params.l_decr = 1;
      this.exdata.params.r_incr = 2;
      this.exdata.params.r_decr = 1;
    }

    if (d === "advanced") {
      this.exdata.params.difficulty = d;
      this.exdata.params.l_incr = 2;
      this.exdata.params.l_decr = 0.5;
      this.exdata.params.r_incr = 2;
      this.exdata.params.r_decr = 0.5;
    }

    this.setState({ difficultyAdjusted: true, difficulty: d });
  }


  async storeCreature_cb(type, data, img) {

    console.log("BACKEND Creature store for user: " + JSON.stringify(this.props.user));

    let resp = await fetch('/api/savecreature', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: this.props.user,
        ts: this.startTs,
        type: type,
        data: data,
        img: img
      })
    })
      .then(response => response.json())

    console.log("-------------------------------------- Creature storResult: ", resp);

    return (resp.id);
  }



  async store_cb(data, startTs) {

    console.log("Exercise store for user: ", JSON.stringify(this.props.user), startTs);

    // if startTs is undefined, raise an error
    if (startTs === undefined) {
      console.error("startTs is undefined");
      startTs = this.startTs;
    }

    
    let sessionRecord = {
      user: this.props.user,
      device: this.context.deviceName,
      ts: startTs,
      type: this.exdata.type,
      exercise: this.props.exercise,
      campaign: this.props.campaign,
      params: this.exdata.params,
      data: data,
      lang: this.state.lang,
      label: this.context.label
    }

    // check if the exercise is part of a group campaign
    if(this.campdata[this.campaign].group === true) {
      sessionRecord.group = true;
    } else {
      sessionRecord.group = false;
    }

    let sessionId = this.context.storeSession(sessionRecord);

    // console.log("GGGG", this.context.sessions);
    // reload!

    return sessionId;

  }


  finished_cb(data, store = true) {
    console.log("Course: finished_cb - child data " + JSON.stringify(data));

    if (Object.keys(data).length === 0) {
      console.error("WTF");
    }

    this.props.finished_cb();

    //this.setState({finished: true});
  }

  backto() {

    this.props.finished_cb();
  }



  render() {

    console.log("Exercise props connected", this.props.connected);

    if (this.state.warning) {
      // <Alert severity="error">
      //   <AlertTitle>Error</AlertTitle>
      //   This is an error alert — <strong>check it out!</strong>
      // </Alert>      

      let s = this.state.warningText;

      // if(this.usb) {
      //   s = "Please disconnect the EDA device from USB, and try again!";
      // } else {
      //   s = "EDA battery level is too low. Please charge it first."
      // }


      return (
        <div>
          <br></br>
          {s}
          <br></br>
          <br></br>

          <Button className="reload-button" variant='contained' color='primary' onClick={this.backto.bind(this)}>Back to exercises</Button>
        </div>
      );
    }

    if (this.exdata.eda) {

      if (this.state.hasBleReturned === true && this.state.hasBle === false) {
        return (
          <div>
            <p>Your device does not have a Bluetooth Low Energy adapter.</p>
          </div>
        );
      }
    }

    if (this.state.finished) {
      return (
        <div>
          <p>
            Congratulations, you have reached the end of this exercise!
          </p>
          <Button className="reload-button" variant='contained' color='primary' onClick={this.backto.bind(this)}>Back to exercises</Button>
        </div>
      )
    }


    if (this.instdata && (!this.state.instructionShown)) {

      console.log("+++++++++++ ", this.instdata, "++++");

      let inst = this.instdata;
      let text = "";

      let lang = this.state.lang;

      if (lang in inst) {
        console.log("Found language inst for ", lang);
        text = inst[lang];
      } else {
        console.log("NOT Found language inst for ", lang);
        //text = inst.values().next().value;
        text = Object.values(inst)[0];
        console.log("inst", inst);
      }

      let langButtons = [];
      for (let l in inst) {
        console.log("langs", l);
        langButtons.push(
          <Button key={'langs' + l} variant='text' color='primary' onClick={
            () => {
              this.setState({ lang: l });
              document.cookie = "lang=" + l;
            }
          }>{l}</Button>

        );
      }

      let vidurl = false;
      let video = false;
      if (this.videoinstdata) {
        if (lang in this.videoinstdata) {
          console.log("Found language video for ", lang);
          vidurl = this.videoinstdata[lang];
        } else {
          console.log("NOT Found language video for ", lang);
          //text = inst.values().next().value;
          vidurl = Object.values(this.videoinstdata)[0];
        }

        console.log("vidurl", vidurl);

        video = <ReactPlayer
          // url="/media/gerisgame.m4v"
          // url="/media/rezilio/cp_instruction.mp4"
          // url={url}
          url= {vidurl}
          width="640"
          height="360"
          volume="1"
          playing={true}
          controls
          // onEnded={this.ended.bind(this)}
          // onStarted={this.started.bind(this)}
        />

      }

      console.log("COOKIE", document.cookie);


      return (
        <div key="ex_div">
          <Box sx={{ m: 'auto', maxWidth: "800px" }}>
            {langButtons}
            <br></br>
            <Typography variant="h4" color="text.secondary">
              {this.exdata.label}
            </Typography>

            <br></br>

            {video}

            <br></br>

            {text}

            <br></br>

            <Button className="reload-button" variant='outlined' color='primary' onClick={this.backto.bind(this)}>Back to exercises</Button>

            <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={
              () => {
                this.setState({ instructionShown: true })
              }
            }>Start</Button>

          </Box>

        </div>
      );
    }

    // if (this.exdata.eda === true) {
    //   return (
    //     <div>Hello</div>
    //   );
    // }

    if (this.exdata.adjustVolume === true && this.state.volumeAdjusted === false) {
      console.log("Need to adjust volme");

      return (
        <AdjustVolume onVolumeAdjusted={this.onVolumeAdjusted.bind(this)}> </AdjustVolume>
      );
    }

    if (this.exdata.adjustDifficulty === true && this.state.difficultyAdjusted === false) {
      console.log("Need to adjust difficulty");

      return (
        <AdjustDifficulty onDifficultyAdjusted={this.onDifficultyAdjusted.bind(this)}> </AdjustDifficulty>
      );
    }

    if(this.campdata[this.campaign].group === true) {

      if(!this.pollTimer) this.pollTimer = setInterval(this.pollGroupStart.bind(this), 1000);
  
      if(!this.state.groupStarted) {
        return (
          <div>
            <Typography variant="h4" color="text.secondary">
              {this.exdata.label}
            </Typography>
            <br></br>
            Waiting for the trainer to start the exercise...
            <br></br>
            <br></br>
            <CircularProgress />
            <br></br>
            <Button className="reload-button" variant='outlined' color='primary' onClick={this.backto.bind(this)}>Cancel</Button>
          </div>  
        );

      } else {
        if(this.pollTimer) clearInterval(this.pollTimer);
      }
    }
        


    if (this.exdata.type === "Waves2Phase") {
      console.log("DDDDD ex ", this.exdata.params);
      return (
        <Waves2Phase exercise={this.state.exercise}
          campaign={this.state.campaign}
          finished_cb={this.finished_cb}
          store_cb={this.store_cb}
          storeCreature_cb={this.storeCreature_cb.bind(this)}
          ref={this.edaRef}
          params={this.exdata.params}
        />
      )
    }

    if (this.exdata.type === "Waves") {
      console.log("DDDDD ex ", this.exdata.params);
      return (
        <Waves exercise={this.state.exercise}
          campaign={this.state.campaign}
          finished_cb={this.finished_cb}
          store_cb={this.store_cb}
          storeCreature_cb={this.storeCreature_cb.bind(this)}
          ref={this.edaRef}
          params={this.exdata.params}
        />
      )
    }

    if (this.exdata.type === "WavesSubliminal") return (
      <WavesSubliminal exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        storeCreature_cb={this.storeCreature_cb.bind(this)}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )


    if (this.exdata.type === "Racing") return (
      <Racing exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "ImageHabituation") return (
      <ImageHabituation exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "FeedbackSlider") return (
      <FeedbackSlider exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "VideoEda") return (
      <VideoEda exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "VideoTutorial") return (
      <VideoTutorial exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )


    if (this.exdata.type === "ShrinkImage") return (
      <ShrinkImage exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "WaveBasic") return (
      <WaveBasic exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        storeCreature_cb={this.storeCreature_cb.bind(this)}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "WaveReinforce") return (
      <WaveReinforce
        exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        storeCreature_cb={this.storeCreature_cb.bind(this)}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "WaveUpdown") return (
      <WaveUpdown exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        storeCreature_cb={this.storeCreature_cb.bind(this)}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "WaveKeywords") return (
      <WaveKeywords exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        storeCreature_cb={this.storeCreature_cb.bind(this)}
        ref={this.edaRef}
        params={this.exdata.params}
        sessions={this.props.sessions}
      />
    )

    if (this.exdata.type === "Audio") return (
      <AudioSession finished_cb={this.finished_cb} store_cb={this.store_cb} ref={this.edaRef} params={this.exdata}> </AudioSession>
    )

    if (this.exdata.type === "Keywords") return (
      <Keywords sessions={this.props.sessions}
        finished_cb={this.finished_cb} store_cb={this.store_cb} ref={this.edaRef} params={this.exdata}> </Keywords>
    )

    if (this.exdata.type === "Text") return (
      <TextSession finished_cb={this.finished_cb} store_cb={this.store_cb} params={this.exdata}> </TextSession>
    )

    if (this.exdata.type === "AudioModule") return (
      <AudioModule sessions={this.props.sessions} finished_cb={this.finished_cb} store_cb={this.store_cb} params={this.exdata}> </AudioModule>
    )


    if (this.exdata.type === "Tetris") return (
      <Tetris exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "RawEda") return (
      <RawEda exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    if (this.exdata.type === "Story") return (
      <Story exercise={this.state.exercise}
        campaign={this.state.campaign}
        finished_cb={this.finished_cb}
        store_cb={this.store_cb}
        ref={this.edaRef}
        params={this.exdata.params}
      />
    )

    return (
      <div>
        Error: exercise not found!
      </div>
    );

    //{this.sectionMap[currentSection]()}
  };
}

export default Exercise;
