import React from 'react';
import exercise_data from '../scripts/exercise_data';
import campaign_data from '../scripts/campaign_data';


import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Topic';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Exercise from './Exercise';
import CircularProgress from '@mui/material/CircularProgress';
// import { TextField } from '@mui/material';
// import { tabsListUnstyledClasses } from '@mui/base';
// import { toHaveStyle } from '@testing-library/jest-dom/matchers';

export default class GroupExerciseSelector extends React.Component {

  constructor(props) {
    super(props);

    // console.log("Campaign =================================================");

    this.campdata = campaign_data();

    this.state = {
      open: false,
    }
  }

  resetOpen() {
    let open = [];
    for (let i in this.props.campaigns) {
      open.push(false);
    }

    // console.log("resetOpen:", open);

    this.setState({
      open: open,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) return;
  }

  componentDidMount() {
    // console.log("componentDidMount resetOpen");
    this.resetOpen();
  }

  handleClick(i) {
    // console.log("Campaign handleClick state:", this.state);

    // deep copy 
    let s = Array.from(this.state.open);

    s[i] = !s[i];

    //this.state.open[i] = !this.state.open[i];

    // console.log("handleClick:", s);

    this.setState({ open: s });

  }

  exerciseSelected(campname, exname) {
    console.log("Campaign selected: ", campname, "exercise:", exname);
    this.setState({ exerciseSelected: exname, campaignSelected: campname });

    this.props.exerciseSelectedCb(campname, exname);
  }

  render() {

    let c = [];

    for (let i in this.props.campaigns) {
      let campname = this.props.campaigns[i];
      let camp = false;

      if(!(campname in this.campdata)) {
        console.log("Error unknown campaign", campname);
        continue;
      }

      camp = this.campdata[campname];

      c.push(
        <ListItemButton key={campname} onClick={this.handleClick.bind(this, i)}>
          <ListItemIcon key={campname + "icon"} >
            <TopicIcon key={campname + "icon2"} />
          </ListItemIcon>
          <ListItemText key={campname + "label"} primary={camp.label} />

          {this.state.open[i] ? <ExpandLess /> : <ExpandMore />}

        </ListItemButton>

      );

      let notReached = 0;

      // console.log("///////////////// CAMPID", campid);
      // console.log(camp.exercises);

      let e = [];
      for (let exname in camp.exercises) {

        // console.log(exname);

        let ex = camp.exercises[exname];      

        let label = ex.label;
        let seclabel = true;
        seclabel = `sss`;

        if(ex.inactive == true) continue;

        let available = true;

        e.push(
          <ListItemButton disabled={!available} key={campname + "_" + ex.label} sx={{ pl: 5 }} onClick={this.exerciseSelected.bind(this, campname, exname)}>
            <ListItemIcon>
              <PlayCircleIcon />
            </ListItemIcon>
            <ListItemText primary={label} secondary={seclabel} />
          </ListItemButton>

        )
      }

      c.push(
        <Collapse key={campname + "bb"} in={this.state.open[i]} timeout="auto" unmountOnExit>
          <List key={campname + "_list"} disablePadding>
            {e}
          </List>
        </Collapse>
      )
    }

    return (
      <Box maxWidth='90%' margin={3} alignItems="center"
        justifyContent="center" display="flex">

        <List
          sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
          component="nav"
          subheader={
            < ListSubheader  >
              <Typography variant="h6">
                Select group exercise
              </Typography>
            </ListSubheader >
          }
        >
          {c}
        </List>


      </Box>
    );

  }
}
