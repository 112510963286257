

import { calcLevelStressProfiling1 } from './calc_level_stress_profiling_1';
import Typography from '@mui/material/Typography';

export const stress_profiling_1_data = {
    stress_profiling_1: {
        label: "Stress profiling 1",
        levelType: "STRESSPROFILING1",
        levelFn: calcLevelStressProfiling1,
        exercises: {

            welcome: {
                label: "Welcome to the module",
                level: 1,
                eda: true,
                type: "VideoTutorial",
                params: {
                    url: "media/hls/ven1_welcome/master.m3u8",
                },
            },

            relax1: {
                label: "Relax 1",
                level: 2,
                eda: true,
                type: "Waves",
                params: {
                    duration: 120000,
                    backgroundImage: "/media/stress_profiling/02relax.jpg",
                    // backgroundSound: "/media/cbtbasic/sounds.mp4",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                                Please stay still and relax for 2 minutes.
                            </Typography>

                        </div>
                    ),
                }
            },

            stressor1: {
                label: "Stressor 1",
                level: 3,
                eda: true,
                type: "Waves",
                params: {
                    duration: 120000,
                    backgroundImage: "/media/stress_profiling/03stressor1memory.jpg",
                    // backgroundSound: "/media/cbtbasic/sounds.mp4",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                            Take a moment to recall a situation where you felt 
                            stressed. Once the memory is clear in your mind, 
                            please start the task. Spend the next two minutes 
                            focusing on this memory. Try to relive the emotions and 
                            sensations you experienced at the time.
                            </Typography>

                        </div>
                    ),
                }
            },


            relax2: {
                label: "Relax 2",
                level: 4,
                eda: true,
                type: "Waves",
                params: {
                    duration: 120000,
                    backgroundImage: "/media/stress_profiling/05stressor2sounds.jpg",
                    // backgroundSound: "/media/cbtbasic/sounds.mp4",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                            Now, take the next two minutes to relax. You can use any relaxation 
                            technique you’re familiar with, or just wait for the tension to diminish. 
                            Take your time and allow yourself to fully unwind.
                            </Typography>
                        </div>
                    ),
                }
            },

            stressor2: {
                label: "Stressor 2",
                level: 5,
                eda: true,
                type: "Waves",
                params: {
                    duration: 120000,
                    backgroundImage: "/media/stress_profiling/05stressor2sounds.jpg",
                    backgroundSound: "/media/stress_profiling/05stressor2sounds.mp3",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                            You will now listen to an audio file. 
                            Please, try to ignore the sounds as much as you can.
                            </Typography>
                        </div>
                    ),
                }
            },

            relax3: {
                label: "Relax 3",
                level: 6,
                eda: true,
                type: "Waves",
                params: {
                    duration: 120000,
                    backgroundImage: "/media/stress_profiling/02relax.jpg",
                    // backgroundSound: "/media/cbtbasic/sounds.mp4",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                            Now, take the next two minutes to relax. You can use any relaxation 
                            technique you're familiar with, or just wait for the tension to diminish. 
                            Take your time and allow yourself to fully unwind.
                            </Typography>
                        </div>
                    ),
                }
            },

            stressor3: {
                label: "Stressor 3",
                level: 7,
                eda: true,
                type: "VideoEda",
                params: {
                    duration: 120000,
                    url: "/media/stress_profiling/07stressor3math.mp4",
                    showWaves: false,
                    point_mult: 1,
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                            You will now complete a series of math tasks. 
                            Solve the subtraction problems in your head as quickly as possible!
                            </Typography>
                        </div>
                    ),
                }
            },

            relax4: {
                label: "Relax 4",
                level: 8,
                eda: true,
                type: "Waves",
                params: {
                    duration: 120000,
                    backgroundImage: "/media/stress_profiling/02relax.jpg",
                    // backgroundSound: "/media/cbtbasic/sounds.mp4",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                            Now, take the next two minutes to relax. You can use any relaxation 
                            technique you're familiar with, or just wait for the tension to diminish. 
                            Take your time and allow yourself to fully unwind.
                            </Typography>
                        </div>
                    ),
                }
            },

            stressor4: {
                label: "Stressor 4",
                level: 9,
                eda: true,
                type: "VideoEda",
                params: {
                    duration: 150000,
                    url: "/media/stress_profiling/09stressor4cpt.mp4",
                    showWaves: false,
                    point_mult: 1,
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                            In this task, you will need to place your hand in a tank of ice cold water 
                            (between 0 and 2°C). Keep your hand in the water for as long as you can, 
                            up to a maximum of 2 minutes. 
                            You may remove your hand at any time if you feel it's necessary.
                            </Typography>
                        </div>
                    ),
                }
            },

            relax5: {
                label: "Relax 5",
                level: 10,
                eda: true,
                type: "Waves",
                params: {
                    duration: 120000,
                    backgroundImage: "/media/stress_profiling/02relax.jpg",
                    // backgroundSound: "/media/cbtbasic/sounds.mp4",
                    continueOnZero: true,
                    hideWaves: true,
                    point_mult: 1,
                },
                instruction: {
                    en: (
                        <div>
                            <Typography variant="body1" align='justify' color="text.primary">
                            Now, take the next two minutes to relax. You can use any relaxation 
                            technique you're familiar with, or just wait for the tension to diminish. 
                            Take your time and allow yourself to fully unwind.
                            </Typography>
                        </div>
                    ),
                }
            },
        }
    }

}
