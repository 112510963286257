
import { CalcSessionStats } from './calc_sessionstats';
import { getWeek } from './getWeek';
import features from '../scripts/features';
import campaign_data from './campaign_data';

function isValidEdaSession(s) {
    if (!s.data) return false;
    if (!s.data.eda) return false;
    if (s.data.eda.length < 10) return false;

    if (!s.data.scores) return false;
    if (s.data.scores.length<10) return false;

    let dur = s.data.eda[s.data.eda.length-1][0] - s.data.eda[0][0];
    //if(dur < 60*1000) return false;

    return true;
}

const levels = {

    1: {
        n_maxlevel: 6,
        relax: 70
    },


    2: {
        relax: 250,
        decr: 150,
        sustain: 150,
        trees: 3
    },

    3: {},

    4: {},

    5: {},

    6: {}

};

function getStat(ses) {
    let sessions = 0;
    let relax = 0;
    let decr = 0;
    let sustain = 0;
    let trees = 0;
    let flowers = 0;

    let levelsessions = new Array(100).fill(0);

    let campdata = campaign_data();

    for (let s of ses) {

        if(! s.campaign ) continue;
        let camp = campdata[s.campaign];
        if(camp.levelType != 'CTWKIDS') continue;
        let ex = camp.exercises[s.exercise];

        // if (s.type !== 'Waves2Phase' && s.type !== 'WaveBasic' && s.type !== 'Tetris') continue;

        if (!isValidEdaSession(s)) continue;

        let slevel = ex.level;

        levelsessions[slevel] += 1;

        //console.log(s);

        let calcSessionStats = new CalcSessionStats();
        let stats = calcSessionStats.calc_stats(s);

        //console.log(stats);

        if(stats.relaxpts) relax += stats.relaxpts;
        if(stats.decr) decr += stats.decr;
        if(stats.sustain) sustain += stats.sustain;

        sessions += 1;

        if(s.data.creature) {
            if(s.data.creature.type==="tree") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

            if(s.data.creature.type==="flower") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

        }
    }

    return {
        sessions: sessions,
        relax: relax,
        decr: decr,
        sustain: sustain,
        trees: trees,
        levelsessions: levelsessions
    }
}



export function calcLevelCTWkids(sessions) {
    //console.log("calcLevelCTWkids");


    let level = 1;
    let res, info;

    let s = getStat(sessions);
    //console.log("levelstat", s);

    let text = "";

    let currentLevel = 1;

    for(currentLevel in levels) {
        let l = levels[currentLevel];

        //console.log("KIDS LEVEL CRITERIA", currentLevel, l);

        text = false;
        if(l.sessions && l.sessions > s.sessions) {
            text = `${l.sessions - s.sessions} more sessions` ;
        }


        if(l.n_maxlevel && l.n_maxlevel > s.levelsessions[currentLevel]) {

            //console.log("UUUU", l.n_maxlevel , s.levelsessions[currentLevel]);

            text = `${l.n_maxlevel - s.levelsessions[currentLevel]} more sessions on this level` ;
        }

        if(l.relax && l.relax > s.relax) {
            if(text) text+=", "; else text="";
            text += `${l.relax - s.relax} more relax points` ;
        }

        if(l.decr && l.decr > s.decr) {
            if(text) text+=", "; else text="";
            text += `${l.decr - s.decr} more control points` ;
        }

        if(l.sustain && l.sustain > s.sustain) {
            if(text) text+=", "; else text="";
            text += `${l.sustain - s.sustain} more balance points` ;
        }

        if(l.trees && l.trees > s.trees) {
            if(text) text+=", "; else text="";
            text += `${l.trees - s.trees} more flowers / trees` ;
        }

        //console.log("1", text);

        if(text) break;

    }

    //console.log("KIDS FINAL TEXT", currentLevel, text);

    if(text) {
        info = "To reach the next level, you need to get "+text;
    } else {
        info = "You have reached the final level!";
        //currentLevel+=1;
    }


    // features
    let earnedfeatures = [];
    let feat = features();
    // console.log(s);
    for(let f of feat.fg) {
        //console.log(f);

        let pass = true;
        if(f.sessions && s.sessions < f.sessions) pass = false;
        if(f.decr && s.decr < f.decr) pass = false;
        if(f.relax && s.relax < f.relax) pass = false;
        if(f.sustain && s.sustain < f.sustain) pass = false;
        if(f.trees && s.trees < f.trees) pass = false;

        if(pass) {
            earnedfeatures.push(f.id);
        }



    }
    // console.log("KIDS earnedfeatures", earnedfeatures);


    // OVERRRIDE!!!
    // currentLevel = 1;
    // info = "";

    return [currentLevel, info, earnedfeatures];

}


