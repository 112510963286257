import React, { Component } from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { withSnackbar } from 'notistack';

import { DataGrid } from '@mui/x-data-grid';
import { Paper, TextField, TextareaAutosize } from '@mui/material';

import campaign_data from '../scripts/campaign_data';

import { MyContext } from '../MyContext';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';


class ManageCourses extends Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);


    this.state = {
      levels: [],
      users: [],
      waiting: [],
    }

    this.campdata = campaign_data();

    // this.levels = [];


    console.log("ManageCourses contructor");
  }

  componentDidMount() {
    this.getCourses();

    // update waiting users every 2 seconds
    this.interval = setInterval(() => this.updateWaitingUsers(), 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async getCourses(user) {
    let resp = await fetch('/api/getcoursedata', { method: 'POST' });

    let courses = await resp.json();
    console.log("Courses:", courses);

    for (let c of courses) {
      this.state.levels[c.course] = c.level;

      let users = await this.getCourseUsers(c.course);

      this.state.users[c.course] = [];
      for (let u of users) this.state.users[c.course].push(u.user);

      console.log("users", c.course, users);
    }

    this.setState({ courses: courses, levels: this.state.levels, users: this.state.users });

  }

  async saveCourse(course) {
    let resp = await fetch('/api/savecoursedata', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        course_id: course,
        level: this.state.levels[course]
      })
    });

    await resp.json();
  }

  async getCourseUsers(course) {
    let resp = await fetch('/api/getcourseusers', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        course: course,
      })
    });

    let users = await resp.json();
    return users;
  }


  // async onSubmit(event) {

  //   console.log("onSubmit:" + this.state.user);

  //   event.preventDefault();

  //   this.userData = await this.getUser();

  //   let resp = await fetch('/api/getcampaigns', {
  //     method: 'POST',
  //     body: JSON.stringify({ user: this.state.user }),
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //   })

  //   console.log("resp:", resp);

  //   if (resp.status != 200) {
  //     this.props.enqueueSnackbar('Error fetching campaigns.', { variant: 'error' });
  //     return;
  //   }

  //   let pp = await resp.json();


  //   let p = [];
  //   if (pp.campaigns) p = pp.campaigns;

  //   console.log("Get campaigns loaded:" + JSON.stringify(p));

  //   this.selected = [];
  //   for (let camp of Object.keys(this.campaign_data)) {
  //     // let camp = this.campaign_data[i];

  //     console.log("CAMP", camp);

  //     if ((p.indexOf(camp) > -1)) this.selected.push(true);
  //     else this.selected.push(false);

  //   }

  //   console.log("selected", this.selected);

  //   let label = "";
  //   if (pp.label) label = pp.label;

  //   this.setState({ userSelected: true, labelValue: label });
  // }

  handleChange(id, event) {
    console.log("event:", id, event);

    let value = parseInt(event.target.value);
    if (isNaN(value)) return;
    if (value < 0) return;

    this.state.levels[id] = value;

    this.setState({ levels: this.state.levels });

    // console.log("SELECTED", this.selected);
  }

  async handleSaveClicked(id, event) {
    console.log("handleSaveClicked", id, event);

    let course = id;

    this.saveCourse(course)
  }

  async handleManageUsersClicked(id, event) {
    console.log("handleManageUsersClicked", id, event);

    let course = id;

    this.setState({ manageCourse: course });

    let users = await this.getCourseUsers(course);
    console.log("users", users);
  }

  // periodiacally update the list of users waiting for the exercise to start by polling the server
  // the response is in the format {"user1": {course: "course1", exercise: "exercise1", "coudevice: "device1"}, ...}}
  // parse the response and update the state
  async updateWaitingUsers() {
    let resp = await fetch('/api/getwaitingusers', { method: 'POST' });

    console.log("waiting users response", resp);

    let users = await resp.json();

    let waiting = {};

    console.log("waiting users", users);

    for (let u of Object.keys(users)) {
      waiting[u] = {
        campaign: users[u].campaign,
        exercise: users[u].exercise,
        device: users[u].device
      };
    }

    this.setState({ waiting: waiting });

  }

  async handleStartExerciseClicked() {
    console.log("handleStartExerciseClicked");

    let resp = await fetch('/api/startexercise', { method: 'POST' });

    console.log("start exercise response", resp);


  }

  render() {

    // if(this.state.manageCourse) {
    //   return (<div>Manage</div>);
    // }


    if (!this.state.courses) {
      return (<div></div>);
    }

    let s = [];

    console.log("waiting", this.state.waiting);
    // create a string with the list of users waiting for the exercise to start

    let waiting = {};
    for (let u of Object.keys(this.state.waiting)) {
      let id = this.state.waiting[u].campaign + "/" + this.state.waiting[u].exercise;

      if (!(id in waiting)) waiting[id] = [];
      waiting[id].push(u);
    }

    console.log("waiting", waiting);

    let waitstr = "";

    // if there are more than one keys, add a "WARNING" string to the waitstr
    if (Object.keys(waiting).length > 1) {
      waitstr = "WARNING: Users are waiting for different exercises to start\n";
    }

    // pretty print the list of users waiting for the exercise to start
    for (let w of Object.keys(waiting)) {
      waitstr += w + ": " + waiting[w].join(", ") + "\n";
    }

    console.log("waitstr", waitstr);


    // the first card is a full width special card for listing users who are waiting for the exercise to start
    s.push(
      <Grid key="waiting" item xs={12} md={12} lg={12}>
        <Card>
          <CardContent>
            <Typography variant="body1" component="div">
              Users waiting for the exercise to start
              <br></br>
              <textarea readOnly={true} cols={30} rows={5}
                  value = {waitstr}
              />
            </Typography>

            <Button size="small" onClick={this.handleStartExerciseClicked.bind(this)}>
              Start exercise for all users
            </Button>
          </CardContent>
        </Card>
      </Grid>
    )


    for (let course of this.state.courses) {

      let c = []
      for (let i of course.campaigns) {
        c.push(this.campdata[i].label)
      }


      let level = course.level;
      if (course.course in this.state.levels) level = this.state.levels[course.course];

      console.log("--levels:", this.state.levels);
      console.log("--users", this.state.users);

      console.log("course", course);
      s.push(
        <Grid key={"k_"+course.course} item xs={12} md={6} lg={6}>
          <Card>
            <CardContent>
              {course.course}
              <br></br>
              <Typography variant="body1" component="div">
                {c.toString()}
                <br></br>
                <TextField
                  id="level"
                  label="Limit level to"
                  type="number"
                  value={level}
                  onChange={this.handleChange.bind(this, course.course)}
                  // placeholder="Contact phone number"
                  margin="normal"
                />
                <br></br>
                <textarea readOnly={true} cols={30} rows={5} 
                  value={this.state.users[course.course].join(", ")}
                />
              </Typography>

            </CardContent>
            <CardActions>
              <Button size="small" onClick={this.handleSaveClicked.bind(this, course.course)}>
                Save
              </Button>
              {/* <Button size="small" onClick={this.handleManageUsersClicked.bind(this, course.course)}>
                Manage users
                </Button> */}

            </CardActions>
          </Card>
        </Grid>
      )
    }

    return (
      <Grid container spacing={3} justifyContent='center'>
        {s}
      </Grid>
    );
  }

  // return (
  //   <Box maxWidth='90%' margin={3} alignItems="center" justifyContent="center" display="flex">
  //     <form onSubmit={this.onSubmit.bind(this)}>
  //       <p>Select user to manage:</p>
  //       <input
  //         type="text"
  //         name="user"
  //         value={this.state.user}
  //         placeholder=""
  //         onChange={event => this.setState({
  //           user: event.target.value,
  //         })}
  //         required
  //       />
  //       <input type="submit" value="Submit" />
  //     </form>
  //   </Box>

  // );
}


export default withSnackbar(ManageCourses);