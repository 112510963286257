import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { Link as RouterLink, MemoryRouter } from 'react-router-dom';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { MyContext } from './MyContext';
import { PropaneSharp } from '@mui/icons-material';


export default function NavigateBar({ user, isRoot, hasPupils, isLoggedIn, onLogoutClicked, onLoginClicked, onDisconnectClicked }) {
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);    

    const navigate = useNavigate();


    const myContext = useContext(MyContext);

    let manageCourses = false;
    if(myContext) manageCourses = myContext.userRecord.manageCourses;


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        console.log(e);
        setAnchorEl(null);
    };

    const handleRegister = () => {
        setAnchorEl(null);
        navigate("/register");

    }

    const handleManage = () => {
        setAnchorEl(null);
        navigate("/manage");

    }

    const handleManageCourses = () => {
        setAnchorEl(null);
        navigate("/manage_courses");
    }

    const handleManageGroupExercise = () => {
        setAnchorEl(null);
        navigate("/manage_group_exercise");
    }


    const handleHome = () => {
        console.log("handleHome");
        myContext.setPage("home");
        setAnchorEl(null);
        navigate('/');

    }

    const handlePrivacy = () => {
        setAnchorEl(null);
        navigate('/privacy');

    }

    const handleResults = () => {
        setAnchorEl(null);
        navigate('/inspectuser');
    }

    const handlePupils = () => {
        setAnchorEl(null);
        navigate('/inspectpupil');
    }

    const handleInspectGroupSessions = () => {
        setAnchorEl(null);
        navigate('/inspectgroupsessions');
    }

    const handleFractal = () => {
        setAnchorEl(null);
        navigate('/fractal');
    }

    const handleScenery = () => {
        setAnchorEl(null);
        navigate('/scenery');
    }
    const handleOta = () => {
        setAnchorEl(null);
        navigate('/ota');
    }
    
    const handleDisconnect = () => {
        setAnchorEl(null);
        onDisconnectClicked();
        navigate('/');
    }

    // const onHomeClicked = () => {
    //     setAnchorEl(null);
    //     navigate('/');
    // }

    const onLoginClicked2 = () => {
        setAnchorEl(null);
        navigate('/');
        onLoginClicked();
    }


    var loginButton = null;
    if (isLoggedIn) {
        loginButton = (
            <Button color="inherit" key="logout" onClick={onLogoutClicked}>
                Logout
            </Button>);
    } else {
        loginButton = (
            <Button color="inherit" key="login" onClick={onLoginClicked2}>
                Login
            </Button>);
    }

    let avatar = "";
    if (user) avatar = user.substring(0, 2).toUpperCase();

    let tooltip="";
    let deviceName=false;

    if(myContext) {
        deviceName = myContext.deviceName;
        tooltip = "Battery: "+myContext.bat+"V" + " Firmware: "+myContext.firmware;
    }

    return (
        <Box sx={{ flexGrow: 1 }} >

            <AppBar position="static">
                <Toolbar >

                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}
                        onClick={handleMenu}>
                        <MenuIcon />
                    </IconButton>

                    <Button color="inherit" onClick={handleHome}>
                        HOME
                    </Button>

                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        {/* <Typography variant="h6" sx={{alignSelf: "center"}}> */}
                        {/* Add link to Obimon.com */}
                        <Link href="https://obimon.com" underline="none" color="inherit">
                            Obimon
                        </Link>
                    </Typography>

                    {deviceName &&
                        <Tooltip title={tooltip} arrow>
                            <Typography variant="h6" sx={{ mr: 1 }}>
                                {deviceName} 
                            </Typography>
                        </Tooltip>
                    }

                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        {isLoggedIn && <Avatar>{avatar}</Avatar>}

                    </IconButton>

                    {loginButton}

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        {/* <MenuItem onClick={handleHome}>Home</MenuItem> */}
                        <MenuItem onClick={handleHome}>Home</MenuItem>
                        <MenuItem onClick={handlePrivacy}>Privacy policy</MenuItem>
                        {deviceName && <MenuItem onClick={handleDisconnect}>Disconnect Obi</MenuItem>}
                        {isRoot && <MenuItem onClick={handleRegister}>Register new user</MenuItem>}
                        {isRoot && <MenuItem onClick={handleManage}>Manage user</MenuItem>}
                        {(isRoot||manageCourses) && <MenuItem onClick={handleManageCourses}>Manage courses</MenuItem>}
                        {(isRoot||manageCourses) && <MenuItem onClick={handleManageGroupExercise}>Select group exercise</MenuItem>}
                        {isRoot && <MenuItem onClick={handleResults}>Inspect user records</MenuItem>}
                        {hasPupils && <MenuItem onClick={handlePupils}>Inspect pupil records</MenuItem>}
                        {manageCourses && <MenuItem onClick={handleInspectGroupSessions}>Inspect group records</MenuItem>}
                        {isRoot && <MenuItem onClick={handleFractal}>Fractal</MenuItem>}
                        {isRoot && <MenuItem onClick={handleScenery}>Scenery</MenuItem>}
                        {isRoot && <MenuItem onClick={handleOta}>Ota</MenuItem>}

                    </Menu>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
